import React from 'react'
import styled from "styled-components"
import CEOPicture from "../images/ceo_picture.png"

import JavascriptLogo from '../images/javascript_logo.png'
import HtmlLogo from '../images/html_logo.png'
import CssLogo from '../images/css_logo.png'
import ReactLogo from '../images/react_logo.png'
import ReduxLogo from '../images/redux_logo.png'
import TypescriptLogo from '../images/typescript_logo.png'
import SassLogo from '../images/sass_logo.png'
import NodeJsLogo from '../images/nodejs_logo.png'
import GraphQlLogo from '../images/graphql_logo.png'
import ApolloLogo from '../images/apollo_logo.png'
import MongoDBLogo from '../images/mongodb_logo.png'
import FirebaseLogo from '../images/firebase_logo.png'
import HerokuLogo from '../images/heroku_logo.png'
import PostgresLogo from '../images/postgresql_logo.png'
import MySqlLogo from '../images/mysql.png'
import AwsLogo from '../images/aws_logo.png'
import TrelloLogo from '../images/trello_logo.png'
import GoogleLogo from '../images/google_logo.png'
import NextLogo from '../images/nextjs_logo.png'
import GatsbyLogo from '../images/gatsby_logo.png'
import FigmaLogo from '../images/figma_logo.png'
import DriveLogo from '../images/drive_logo.png'
import JenkinsLogo from '../images/jenkins.png'
import JiraLogo from '../images/jira_logo.png'
import SlackLogo from '../images/slack_logo.png'
import SkypeLogo from '../images/skype_logo.png'
import GithubLogo from '../images/github_logo.png'
import GitlabLogo from '../images/gitlab_logo.png'
import BitBucketLogo from '../images/bitbucket_logo.png'

// Styles
import '../styles/reset.css'
import { MainComponent } from '../styles/shared.styles'

import SimpleHeader from '../components/SimpleHeader'
import UsSection from '../components/UsSection'
import Footer from '../components/Footer'

export default function whoWeAre() {
  return (
    <MainComponent>
      <SimpleHeader transparent color="#6A2C70"/>
      <UsSection hideSeeMore embedded/>

      <MissionSection>
        <h2>Our mission:</h2>
        <p>Our company's purpose is to:</p>
        <ul>
          <li>Deliver in time, always synced with the customer.</li>
          <li>Deliver code with excellent quality.</li>
          <li>Provide documentation so customers can understand the source code provided.</li>
          <li>Deliver accessible websites and applications.</li>
          <li>Deliver optimized and performant applications.</li>
          <li>Deliver intuitive tools and explain to our customers how they work.</li>
        </ul>
      </MissionSection>

      <Work>
        <h2>The way we work:</h2>
        <p>We start receiving customer inquiries, and we reach them to know more about them, their business and their ideas. Then, if it's a match, we define deadlines, versions, documentation, design and tech stack. After that, it's our job to deliver what the customer wants in time and quality.</p>
      </Work>

      <Tech>
        <h2>Our Tech stack and services:</h2>
        <TechLogosWrapper>
          <img src={JavascriptLogo} alt="Javascript logo" title="Javscript" />
          <img src={HtmlLogo} alt="html logo"  title="HTML" />
          <img src={CssLogo} alt="css logo" title="CSS" />
          <img src={ReactLogo} alt="React logo"  title="React.js" />
          <img src={TypescriptLogo} alt="typescript logo" title="TypeScript" />
          <img src={SassLogo} alt="sass logo" title="SASS" />
          <img src={NodeJsLogo} alt="nodejs logo" title="Node.js" />
          <img src={GraphQlLogo} alt="graphql logo" title="GraphQL" />
          <img src={ApolloLogo} alt="apollo logo" title="Apollo Client & Server" />
          <img src={MongoDBLogo} alt="mongodb logo" title="MongoDB" />
          <img src={FirebaseLogo} alt="firebase logo" title="Firebase" />
          <img src={HerokuLogo} alt="heroku logo" title="Heroku" />
          <img src={PostgresLogo} alt="postgres logo" title="PostgresQL" />
          <img src={MySqlLogo} alt="mysql logo" title="MySQL" />
          <img src={AwsLogo} alt="aws logo" title="AWS" />
          <img src={TrelloLogo} alt="trello logo" title="Trello" />
          <img src={GoogleLogo} alt="google services logo" title="Google Services" />
          <img src={NextLogo} alt="nextjs logo" title="Next.js" />
          <img src={GatsbyLogo} alt="gatsby logo" title="Gatsby" />
          <img src={ReduxLogo} alt="Redux logo" title="Redux" />
          <img src={DriveLogo} alt="drive logo" title="Drive" />
          <img src={JenkinsLogo} alt="jenkins logo" title="Jenkins" />
          <img src={JiraLogo} alt="Jira logo" title="Jira" />
          <img src={SlackLogo} alt="slack logo" title="Slack" />
          <img src={SkypeLogo} alt="skype logo" title="Skype" />
          <img src={GithubLogo} alt="github logo" title="Github"  />
          <img src={GitlabLogo} alt="gitlab logo" title="Gitlab" />
          <img src={BitBucketLogo} alt="bitbucket logo" title="BitBucket" />
          <span>And more...</span>
        </TechLogosWrapper>
      </Tech>
      <Footer />
    </MainComponent>
  )
}

const MissionSection = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 23px;
  padding: 200px 50px;
  padding-top: 50px;

  h2 {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 40px;
  }

  ul {
    list-style-type: disc;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 20px;

    ul {
      list-style-type: circle;
    }

    ul ul {
      list-style-type: square;
    }
  }
`
const Work = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 23px;
  padding: 200px 50px;
  padding-top: 50px;

  h2 {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 40px;
  }
`

const Tech = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 23px;
  padding: 200px 50px;
  padding-top: 50px;

  h2 {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 40px;
  }

  img {
    margin: 15px;
    width: 50px;
    height: 50px;
  }

  @media screen and (min-width: 1280px) {
    img {
      width: 144px;
      height: 144px;
    }
  }
`

const TechLogosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  span {
    height: 144px;
    width: 144px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
  }
`